import './App.css';
import { Route, Routes } from 'react-router-dom';
import MainLayout from './components/MainLayout';
// import Section from '../src/components/Section';
import SectionOld from './components/SectionOld';

import SendMsg from './components/SendMsg';
import Records from './components/Records';
import Login from './components/Login';


function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<MainLayout />}>
          {/* <Route path='/' element={<Section/>} /> */}
          <Route path='/' element={<SectionOld/>}/>
          <Route path='/login' element={<Login />} />
          <Route path='/home' element={<SendMsg />} />
          <Route path='/admin/records' element={<Records/>} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
