import React from "react";
import "../App.css";

export default function Footer() {
  return (
    <footer className="text-center" style={{ marginTop: "50px" }}>
      <p className="m-0">
        Powered by Foxberry Technologies Pvt. Ltd © PMC Cares, All rights
        reserved.
      </p>
    </footer>
    // <div className='footer' style={{width:'100%',height:'70px',backgroundColor:'#e3f2fd',display:'flex',justifyContent:'center',alignItems:'center', marginTop:'auto'}}>
    //     <div className='text-primary'>© Gomantak, All rights reserved.</div>
    // </div>
  );
}
