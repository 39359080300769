// import React from 'react'
// import walogo2 from '../asset/walogo2.png'
// import { Container, Row, Col } from 'react-bootstrap';
// import { useNavigate } from "react-router-dom";
// import Typed from 'react-typed';
// // import Banner from '../asset/Banner.jpg';
// // import Typewriter from "typewriter-effect";
// import gif from '../asset/white-Broadcast.gif';

// export default function Section() {
//     const navigate = useNavigate();

//     const textLines = [
//         `ब्रेकिंग न्यूज`,
//         `माहिती आणि मनोरंजन`,
//         `शॉपिंगची धमाल`,
//         `स्पर्धा परीक्षा मार्गदर्शन`
//       ];
//     return (
//         <div>

//             <Container fluid className="home-section">
//                 <Row className=" d-flex justify-content-center align-items-center h-100">
//                     <Col md={10} className="text-center">

//                         <div className='typeWriter'>
//                         <Typed strings={textLines} typeSpeed={60} backSpeed={50} loop/>
//                         </div>
//                         {/* <div className='typeWriter-2'>
//                         <Typed strings={textLines} typeSpeed={60} backSpeed={50} loop/>
//                         </div> */}

//                     {/* <div className="typeWriter text-center">
//                         <Typewriter
//                         style={{FontFace}}
//                         options={{autoStart:true, loop:true}}
//                         onInit={(typewriter) => {
//                             typewriter
//                             .start()
//                             .typeString("ब्रेकिंग न्यूज")
//                             .pauseFor(1000)
//                             .deleteAll()
//                             .typeString("माहिती आणि मनोरंजन")
//                             .pauseFor(1000)
//                             .deleteAll()
//                             .typeString("शॉपिंगची धमाल")
//                             .pauseFor(1000)
//                             .deleteAll()
//                             .typeString("स्पर्धा परीक्षा मार्गदर्शन")
//                             .pauseFor(1000)
//                             .deleteAll()
//                             .start()
//                         }}
//                         />
//                         </div> */}

//                         <div>
//                             {/* Small h2 font only visible For Smaller Devices like Mobiles*/}
//                             <div className="whatsApp-Heading d-flex align-items-center justify-content-center mt-3 d-block d-md-none">
//                                 <h2 className='mb-0'>आता तुमच्या
//                                     <img src={walogo2} style={{ width: '40px' }} className="ms-2 me-1" alt="WhatsApp" />
//                                     <span className='whatsApp me-2'>WhatsApp</span> वर
//                                 </h2>
//                             </div>
//                               {/* Big h1 font only visible For Larger Devices like Ipad or Laptops */}
//                             <div className="whatsApp-Heading d-flex align-items-center justify-content-center mt-3 d-none d-md-block">
//                                 <h1 className='mb-0'>आता तुमच्या
//                                     <img src={walogo2} style={{ width: '40px' }} className="ms-2 me-1" alt="WhatsApp" />
//                                     <span className='whatsApp me-2'>WhatsApp</span> वर
//                                 </h1>
//                             </div>
//                             <div>
//                                 <h3 className='my-3'>किंवा</h3>
//                                 <div>
//                                 <button type="button" className="btn btn-submit mb-4" onClick={() => navigate('/home')}>जॉईन करा <img src={gif} className='img-fluid' style={{width:'45px'}}/></button>

//                                 </div>
//                                 <h5>खाली दिलेल्या WhatsApp नंबर वरती Hi टाईप करा आणि मिळवा लेटेस्ट अपडेट्स आता तुमच्या फोनवरती</h5>
//                                 <div className="alert alert-pills  mt-2 mx-auto" role="alert">
//                                 <a href="tel:9172226320"><h5 className="text-dark mb-0" style={{fontWeight:'500'}}>+91 96070 14391</h5></a>
//                                 </div>
//                             </div>

//                             <p className="text-center text-muted mt-3 mb-2" style={{ fontWeight: '600' }}>
//                                 चला तर मग, आजच जॉईन करा🤝
//                             </p>
//                             <div className="alert alert-pills  mt-2 mx-auto" role="alert">
//                                 <span className="content text-dark " style={{fontWeight:'500'}}> Join Now, Absolutely </span>
//                                 <span
//                                     className="badge badge-pill badge-success ms-1"
//                                     style={{ backgroundColor: "#25D366", borderRadius: "10px" }}>Free
//                                 </span>

//                             </div>

//                         </div>

//                     </Col>
//                 </Row>

//                 {/* <Row className="justify-content-center">
//         <Col md={8} className="mt-3 mb-5">
//         <img src={Banner} className='img-fluid' style={{borderRadius:'15px'}} alt="Home"/>
//         </Col>
//        </Row> */}
//             </Container>

//             {/* <section className="bg-half-170 pb-0 bg-light d-table w-100" style={{height:'73vh'}}>
//             <div className="container">
//                 <div className="row justify-content-center">
//                     <div className="col-lg-10">
//                         <div className="title-heading text-center mt-4 mt-md-5 pt-md-5">
//                             <h3 className="heading mb-2">
//                                 <span className="element text-primary"
//                                     data-elements="ब्रेकिंग न्यूज, जॉब अपडेट्स, माहिती आणि मनोरंजन ,शॉपिंगची धमाल, स्पर्धा परीक्षा मार्गदर्शन "></span>
//                                 <br/>
//                                 <span style={{ fontSize: "24px !important" }}>आता तुमच्या <span style={{ color: "#25D366" }}> <img src={walogo} className="logowa" alt="" />WhatsApp</span> वर</span>
//                             </h3>

//                             <p className="para-desc mx-auto text-muted" style={{ marginBottom: "10px !important" }}>
//                                 चला तर मग, आजच दैनिक गोमंतक जॉईन करा🤝
//                             </p>

//                             <div className="alert alert-pills" role="alert">

//                                 <span className="content"> Join Now, Absolutely </span>
//                                 <span className="badge badge-pill badge-success mr-1" style={{ backgroundColor: "#25D366" }}> Free</span>
//                             </div>
//                             <button type="button" className="btn btn-outline-primary" onClick={()=>navigate('/home')}>Proceed</button>
//                         </div>

//                         {/* <img src={Banner} className="img-fluid" alt='banner' style={{borderRadius:'15px'}}/> */}
//             {/* </div>
//                 </div>
//             </div>
//         </section> */}

//         </div>
//     )
// }

import React, { useEffect, useState } from "react";
import walogo2 from "../asset/walogo2.png";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";

import Typed from "react-typed";
// import Banner from '../asset/Banner.jpg';
// import Typewriter from "typewriter-effect";
import gif from "../asset/white-Broadcast.gif";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
export default function Section() {
  // const navigate = useNavigate();

  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState(false);
  const [prefferror, setprefferror] = useState(false);
  const [Error, setError] = React.useState("");
  const [lang, setLang] = React.useState("");

  const [prefArray, setPrefArray] = useState([]);
  const [selectedPref, setSelectedPref] = useState([]);
  const [selectedid, setSelectedid] = useState([]);
  const [textLinesMarathi, settextLinesMarathi] = useState([]);
  const [textLinesENGLSIH, settextLinesENGLSIH] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    getPreferences();

    getLang();

    console.log("getPrefernces Calling");
  }, []);
  // useEffect(() => {
  //   if (prefArray.length > 0) {
  //     Allcheck();
  //   }
  // }, [checkbox]);

  const getPreferences = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("project", "pmccare");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      "https://whatsupproject.foxberry.link/v1/preference/getpreference",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("API Respones ==>", res);
        setPrefArray(res);
        const empty = [];
        const empty1 = [];
        res.map((e) => {
          empty.push(e.preferenceNameMarathi);
        });
        res.map((res) => {
          empty1.push(res.preferenceNameEnglish);
        });
        settextLinesMarathi(empty);
        settextLinesENGLSIH(empty1);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // const textLinesMarathi = [
  //   `ब्रेकिंग न्यूज`,
  //   `माहिती आणि मनोरंजन`,
  //   `शॉपिंगची धमाल`,
  //   `स्पर्धा परीक्षा मार्गदर्शन`,
  // ];

  // const textLinesENGLSIH = [
  //   "Breaking News",
  //   "Information And Entertainment",
  //   "Enjoy Shopping",
  //   "Competitive Exam And Guidance",
  // ];

  const getLang = () => {
    if (localStorage.getItem("lang") === null) {
      localStorage.setItem("lang", "marathi");
      setLang("marathi");
    } else {
      setLang(localStorage.getItem("lang"));
      // console.log(localStorage.getItem("lang"));
    }
  };

  const changeLang = () => {
    if (lang === "marathi") {
      localStorage.setItem("lang", "english");
      getLang();
      setError("");
    } else if (lang === "english") {
      localStorage.setItem("lang", "marathi");
      getLang();
      setError("");
    }
  };

  const validate = () => {
    console.log("In validate", mobile);
    let FormValid = true;

    if (!mobile) {
      FormValid = false;
      setMobileErr(true);
    } else if (/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(mobile) == false) {
      FormValid = false;
      console.warn(mobile.length);
      setMobileErr(true);
    } else if (selectedPref.length === 0 && selectedid.length === 0) {
      FormValid = false;

      // console.warn(mobile.length);
      setprefferror(true);
    }
    return FormValid;
  };

  const handlecheck = (e, row) => {
    // console.log("In handle check", row?.preferenceNameMarathi);
    if (e.target.checked) {
      if (
        lang === "marathi"
          ? row?.preferenceNameMarathi === "वरील सर्व"
          : row?.preferenceNameEnglish === "All of the above"
      ) {
        var ele = document.getElementsByName("pqr");

        for (var i = 0; i < ele.length; i++) {
          if (ele[i].type === "checkbox") {
            ele[i].checked = true;
          }
        }

        if (lang === "marathi") {
          var ele = document.getElementsByName("pqr");
          const arr1 = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr1.push(ele[i].id);
            }
          }
          setSelectedid(arr1);
          const arr = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr.push(ele[i].value);
            }
          }
          setSelectedPref(arr);
        } else if (lang == "english") {
          var ele = document.getElementsByName("pqr");
          const arr1 = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr1.push(ele[i].id);
            }
          }
          setSelectedid(arr1);
          const arr = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr.push(ele[i].value);
            }
          }
          setSelectedPref(arr);
        }
      } else if (
        lang === "marathi"
          ? row?.preferenceNameMarathi !== "वरील सर्व"
          : row?.preferenceNameEnglish !== "All of the above"
      ) {
        const checkarray = [];
        var ele = document.getElementsByName("pqr");
        for (var i = 0; i < ele.length - 1; i++) {
          if (ele[i].checked === true) {
            checkarray.push(ele[i].value);
          }
        }
        if (checkarray.length === prefArray.length - 1) {
          for (var i = 0; i < ele.length; i++) {
            if (ele[ele.length - 1].type === "checkbox") {
              ele[ele.length - 1].checked = true;
            }
          }
        }
        let arr = selectedPref;
        let arr1 = selectedid;

        if (lang === "marathi") {
          var ele = document.getElementsByName("pqr");
          const arr1 = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr1.push(ele[i].id);
            }
          }
          setSelectedid(arr1);
          const arr = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr.push(ele[i].value);
            }
          }
          setSelectedPref(arr);
        } else {
          var ele = document.getElementsByName("pqr");
          const arr1 = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr1.push(ele[i].id);
            }
          }
          setSelectedid(arr1);
          const arr = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr.push(ele[i].value);
            }
          }
          setSelectedPref(arr);
        }
      }
    }
    if (e.target.checked == false) {
      if (
        lang == "marathi"
          ? row?.preferenceNameMarathi === "वरील सर्व"
          : row?.preferenceNameEnglish === "All of the above"
      ) {
        var ele = document.getElementsByName("pqr");
        for (var i = 0; i < ele.length; i++) {
          if (ele[i].type == "checkbox") {
            ele[i].checked = false;
          }
        }

        if (lang === "marathi") {
          const arr = [];
          setSelectedid(arr);
          setSelectedPref(arr);
        } else {
          const arr = [];
          setSelectedid(arr);
          setSelectedPref(arr);
        }
      } else if (e.target.checked) {
        let arr = selectedPref;
        let arr1 = selectedid;
        if (lang === "marathi") {
          var ele = document.getElementsByName("pqr");
          const arr1 = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr1.push(ele[i].id);
            }
          }
          setSelectedid(arr1);
          const arr = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr.push(ele[i].value);
            }
          }
          setSelectedPref(arr);
        } else {
          var ele = document.getElementsByName("pqr");
          const arr1 = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr1.push(ele[i].id);
            }
          }
          setSelectedid(arr1);
          const arr = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr.push(ele[i].value);
            }
          }
          setSelectedPref(arr);
        }
      }
    }
    if (e.target.checked === false) {
      if (row?.preferenceNameMarathi !== "वरील सर्व") {
        var ele = document.getElementsByName("pqr");
        for (var i = 0; i < ele.length; i++) {
          if (ele[ele.length - 1].type === "checkbox") {
            ele[ele.length - 1].checked = false;
          }
        }
        if (lang === "marathi") {
          var ele = document.getElementsByName("pqr");
          const arr1 = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr1.push(ele[i].id);
            }
          }
          setSelectedid(arr1);
          const arr = [];
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].checked == true) {
              arr.push(ele[i].value);
            }
          }
          setSelectedPref(arr);
        } else if (row?.preferenceNameEnglish !== "All of the above") {
          var ele = document.getElementsByName("pqr");
          for (var i = 0; i < ele.length; i++) {
            if (ele[ele.length - 1].type === "checkbox") {
              ele[ele.length - 1].checked = false;
            }
          }
          if (lang === "english") {
            var ele = document.getElementsByName("pqr");
            const arr1 = [];
            for (var i = 0; i < ele.length; i++) {
              if (ele[i].checked == true) {
                arr1.push(ele[i].id);
              }
            }
            setSelectedid(arr1);
            const arr = [];
            for (var i = 0; i < ele.length; i++) {
              if (ele[i].checked == true) {
                arr.push(ele[i].value);
              }
            }
            setSelectedPref(arr);
          }
        }
      }
    }
  };

  const handleMobile = async () => {
    setprefferror("");
    setMobileErr("");
    if (validate()) {
      setloading(true);
      console.warn(selectedPref.length, prefArray.length);
      if (selectedPref.length == prefArray.length) {
        selectedPref.pop();
        selectedid.pop();
        console.warn("selectedPref", selectedPref);
        console.warn("selectedid", selectedid);
        const data = {
          contactNo: mobile,
          pincode: "",
          selectPreferenceName: selectedPref,
          selectPreferenceId: selectedid,
        };
        await axios
          .post(
            "https://whatsupproject.foxberry.link/v1/pmccares/insertpmccarescontactno",
            data
          )
          .then((res) => {
            setloading(false);
            console.log("responce from handleMobile", res.data);
            setSelectedPref([]);
            setSelectedid([]);
            if (res.data.code === undefined) {
              notify();

              if (lang === "marathi") {
                window.location.href = `https://wa.me/+919067383383?text= ‘नमस्कार!‘PMC CARES’ WhatsApp वर आपले स्वागत आहे.
                महत्त्वाच्या घडामोडी आणि अपडेट्स आता तुमच्या WhatsApp वर तुम्हाला नियमितपणे मिळतील. त्यासाठी कृपया आताच तुमच्या मोबाईल कॉन्टॅक्ट लिस्ट मध्ये आमचा फोन नंबर सेव्ह करा.`;
              } else {
                window.location.href = `https://wa.me/+919067383383?text= Hello there! Welcome to the official PMC CARES WhatsApp.The major stories and updates that matter will now be delivered to your WhatsApp regularly, handpicked by our experts.
                One more thing: Please SAVE our phone number in your CONTACT LIST right now to begin receiving updates from us`;
              }
            } else {
              if (lang === "marathi") {
                window.location.href = `https://wa.me/+919067383383?text= ‘नमस्कार!‘PMC CARES’ WhatsApp वर आपले स्वागत आहे.
                महत्त्वाच्या घडामोडी आणि अपडेट्स आता तुमच्या WhatsApp वर तुम्हाला नियमितपणे मिळतील. त्यासाठी कृपया आताच तुमच्या मोबाईल कॉन्टॅक्ट लिस्ट मध्ये आमचा फोन नंबर सेव्ह करा.`;
              } else {
                window.location.href = `https://wa.me/+919067383383?text= Hello there! Welcome to the official PMC CARES WhatsApp.The major stories and updates that matter will now be delivered to your WhatsApp regularly, handpicked by our experts.
                One more thing: Please SAVE our phone number in your CONTACT LIST right now to begin receiving updates from us`;
              }
              notify1();
            }
          })
          .catch((err) => {
            setloading(false);
            console.log("error in handleMobile", err);
          });
      } else {
        const data = {
          contactNo: mobile,
          pincode: "",
          selectPreferenceName: selectedPref,
          selectPreferenceId: selectedid,
        };
        await axios
          .post(
            "https://whatsupproject.foxberry.link/v1/pmccares/insertpmccarescontactno",
            data
          )
          .then((res) => {
            setSelectedPref([]);
            setSelectedid([]);
            setloading(false);
            console.log("responce from handleMobile", res.data);

            if (res.data.code === undefined) {
              notify();

              if (lang === "marathi") {
                window.location.href = `https://wa.me/+919067383383?text= ‘नमस्कार!‘PMC CARES’ WhatsApp वर आपले स्वागत आहे.महत्त्वाच्या घडामोडी आणि अपडेट्स आता तुमच्या WhatsApp वर तुम्हाला नियमितपणे मिळतील. त्यासाठी कृपया आताच तुमच्या मोबाईल कॉन्टॅक्ट लिस्ट मध्ये आमचा फोन नंबर सेव्ह करा.`;
              } else {
                window.location.href = `https://wa.me/+919067383383?text= Hello there! Welcome to the official PMC CARES WhatsApp.The major stories and updates that matter will now be delivered to your WhatsApp regularly, handpicked by our experts.One more thing: Please SAVE our phone number in your CONTACT LIST right now to begin receiving updates from us.`;
              }
            } else {
              if (lang === "marathi") {
                window.location.href = `https://wa.me/+919067383383?text= ‘नमस्कार!‘PMC CARES’ WhatsApp वर आपले स्वागत आहे.महत्त्वाच्या घडामोडी आणि अपडेट्स आता तुमच्या WhatsApp वर तुम्हाला नियमितपणे मिळतील. त्यासाठी कृपया आताच तुमच्या मोबाईल कॉन्टॅक्ट लिस्ट मध्ये आमचा फोन नंबर सेव्ह करा.`;
              } else {
                window.location.href = `https://wa.me/+919067383383?text= Hello there! Welcome to the official PMC CARES WhatsApp.The major stories and updates that matter will now be delivered to your WhatsApp regularly, handpicked by our experts.One more thing: Please SAVE our phone number in your CONTACT LIST right now to begin receiving updates from us.`;
              }
              notify1();
            }
          })
          .catch((err) => {
            setloading(false);
            console.log("error in handleMobile", err);
          });
      }
    }
  };
  const notify = () => {
    toast.success(
      lang === "marathi" ? "यशस्वीरित्या सामील झाले" : "joined successfully",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  const notify1 = () => {
    toast.success(
      lang === "marathi"
        ? "आपण आधीच सदस्यता घेतलेले आहात"
        : "You are already subscribed",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  if (loading == true) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden ">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <div>
      <Container>
        <ToastContainer autoClose={3000} />
        <Row className=" d-flex justify-content-center align-items-center h-100">
          <Row className="justify-content-end">
            <Col md={1}>
              <label className="switch">
                <input
                  className="switch-input"
                  type="checkbox"
                  onChange={() => changeLang()}
                />
                <span
                  className="switch-label"
                  data-on={lang === "marathi" ? "मराठी" : "ENGLISH"}
                  data-off={lang === "marathi" ? "मराठी" : "ENGLISH"}
                ></span>{" "}
                <span className="switch-handle"></span>
              </label>
            </Col>
          </Row>

          <Col md={10} className="text-center">
            <div className="typeWriter">
              <Typed
                strings={
                  lang === "marathi" ? textLinesMarathi : textLinesENGLSIH
                }
                typeSpeed={60}
                backSpeed={50}
                loop
              />
            </div>

            <div>
              {/* Small h2 font only visible For Smaller Devices like Mobiles*/}
              <div className="whatsApp-Heading d-flex align-items-center justify-content-center mt-3 d-block d-md-none">
                <h2 className="mb-0">
                  {lang === "marathi" ? (
                    <div>
                      <h3>जॉईन करा वर क्लिक करा </h3>
                      <h3>आणि अपडेट्स मिळवा</h3>
                    </div>
                  ) : (
                    <div>
                      <h3>Click On Join Now </h3>
                      <h3>& Receive Updates on</h3>
                    </div>
                  )}
                  <img
                    src={walogo2}
                    style={{ width: "40px" }}
                    className="ms-2 me-1"
                    alt="WhatsApp"
                  />
                  <span className="whatsApp me-2">WhatsApp</span>
                </h2>
              </div>
              {/* Big h1 font only visible For Larger Devices like Ipad or Laptops */}
              <div className="whatsApp-Heading d-flex align-items-center justify-content-center mt-3 d-none d-md-block">
                <h1 className="mb-0">
                  {lang === "marathi" ? (
                    <div>
                      <h3>जॉईन करा वर क्लिक करा </h3>
                      <h3>आणि अपडेट्स मिळवा</h3>
                    </div>
                  ) : (
                    <div>
                      <h3>Click On Join Now </h3>
                      <h3>& Receive Updates on</h3>
                    </div>
                  )}
                  <img
                    src={walogo2}
                    style={{ width: "40px" }}
                    className="ms-2 me-1"
                    alt="WhatsApp"
                  />
                  <span className="whatsApp me-2">WhatsApp</span>{" "}
                  {lang === "marathi" ? "वर" : ""}
                </h1>
              </div>
              <div>
                <Row className="w-75 mx-auto">
                  <h6 className="text-center mt-4 font-weight-bold ">
                    {lang === "marathi"
                      ? "कृपया तुमचे आवडीचे क्षेत्र निवडा:"
                      : "Please select your area of interest:"}
                  </h6>
                  {/* <input
                    type="button"
                    onClick={() => {
                      allInputs();
                    }}
                    id="vehicle1"
                    value="select"
                  ></input> */}
                  {/* <label for="vehicle1">
                    {lang === "marathi" ? "वरील सर्व" : "All of the above"}
                  </label> */}
                  {prefArray.map((row) => (
                    <Col md={4} className="mt-2 text-start">
                      <input
                        value={
                          lang === "marathi"
                            ? row.preferenceNameMarathi
                            : row.preferenceNameEnglish
                        }
                        id={lang === "marathi" ? row._id : row._id}
                        key={row._id}
                        onChange={(e) => {
                          handlecheck(e, row);
                        }}
                        type="checkbox"
                        name="pqr"
                        className="me-1"
                      />
                      {lang === "marathi"
                        ? row.preferenceNameMarathi
                        : row.preferenceNameEnglish}
                    </Col>
                  ))}
                  {prefferror ? (
                    <p
                      style={{
                        color: "red",
                        margin: "0px 20px",
                        fontSize: "12px",
                      }}
                    >
                      {lang === "marathi"
                        ? "कृपया तुमचे आवडीचे क्षेत्र निवडा"
                        : "Please select your area of interest"}
                    </p>
                  ) : null}

                  {/* {prefArray.map((item, index) => (
                              <span key={index} className="d-flex">
                                <input value={item} key={index} type="checkbox" className='me-2'/>
                                <span>{item.preferenceName}</span>
                              </span>
                            ))} */}
                </Row>

                <Row className="justify-content-center text-start mt-5 mb-3">
                  <Col md={4}>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Control
                          placeholder={
                            lang === "marathi" ? "मोबाइल नंबर" : "Mobile Number"
                          }
                          value={mobile}
                          onChange={(e) => {
                            if (
                              !e.target.value.match(
                                /[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,;`'""/]/
                              ) &&
                              e.target.value.length <= 10
                            ) {
                              //console.log(e.target.value);
                              setMobile(e.target.value);
                            }
                          }}
                        />
                        {mobileErr ? (
                          <p
                            style={{
                              color: "red",
                              margin: "0px 20px",
                              fontSize: "12px",
                            }}
                          >
                            {lang === "marathi"
                              ? "कृपया वैध मोबाइल क्रमांक प्रविष्ट करा"
                              : "Please Enter Valid Mobile Number"}
                          </p>
                        ) : null}
                      </Form.Group>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-submit mb-1"
                          onClick={(e) => {
                            e.preventDefault();

                            handleMobile();
                          }}
                        >
                          {lang === "marathi" ? "जॉईन करा" : "Join Now"}
                          <img
                            src={gif}
                            className="img-fluid"
                            style={{ width: "45px" }}
                          />
                        </button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>

              <div className="alert alert-pills  mx-auto" role="alert">
                <span
                  className="content text-dark "
                  style={{ fontWeight: "500" }}
                >
                  {" "}
                  {lang === "marathi"
                    ? "जॉईन करा अगदी"
                    : "Join Now, Absolutely"}{" "}
                </span>
                <span
                  className="badge badge-pill badge-success ms-1"
                  style={{ backgroundColor: "#25D366", borderRadius: "10px" }}
                >
                  Free
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
