import React from 'react'
// import { useSearchParams } from 'react-router-dom';
// import img from '../asset/home.png';
 
import * as Icon from 'react-bootstrap-icons';
import Banner from '../asset/Banner.jpg';
import { Container, Row, Col } from 'react-bootstrap';
// import axios from 'axios';

export default function SendMsg() {
    const [cities, setCities] = React.useState([])
    const [loading,setLoading] = React.useState(false);
    React.useEffect(() => {
        // getCities();
    }, [])

    const [mobileErr, setMobileErr] = React.useState(false);
    const [Error,setError] = React.useState()
    const [mobile, setMobile] = React.useState();
    
    const handleMobile =event=>{
        const result = event.target.value.replace(/[A-Za-z+@#$&%!~=*./\s/g/\-/]/gi,'');
        setMobile(result);
        if(mobile){
            setMobileErr(false);
      }
    }

    const alert = (message, type, element) => {
        const wrapper = document.createElement('div')
        wrapper.innerHTML = [
            `<div class="alert alert-${type} alert-dismissible" role="alert" style="z-index:10; ">`,
            `   <div>${message}</div>`,
            '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
            '</div>'
        ].join('')

        element.append(wrapper)
    }

    // Pincode validation
    const checkPincode = (value, element) => {
        if (value.match(/^\d{6}$/)) {
            if (value.startsWith("403"))
                return true;
            else {
                element.innerHTML = "";
                alert("Please Enter Proper Goa's Pincode !", "danger", element);
                return false;
            }
        } else {
            element.innerHTML = "";
            alert("Please Enter Goa's Pincode !", "danger", element);
            return false;
        }
    }

    // Mobile validation
    const checkMobile = (value, element) => {
        console.log("PATTERN ", value.match(/^\d{10}$/))
        if (value.match(/^\d{10}$/)) {
            return true;
        }else if(value.length === 0){
            setError("*Mobile Number cannot be Empty")
            setMobileErr(true)
            return false;
        }
        else {
            setError("*Please Enter valid mobile number")
            setMobileErr(true)
            return false;
        }
    }

    // Function calling while submiting form
    const onSubmit = async (e) => {
        setMobileErr(false)
        e.preventDefault();
        let bool = false;
        const alertPlaceholder = document.getElementById('liveAlertPlaceholder');
        // let pincode = document.forms["form1"].pincode.value
        let mobileNumber = document.forms["form1"].mobile.value  
        // if (checkPincode(pincode, alertPlaceholder)) {
            // bool = checkMobile(mobileNumber, alertPlaceholder);
            bool = checkMobile(mobileNumber)
        // }

        if (bool) {
            let payload = {
                contactNo: mobileNumber,
                // pincode: pincode,
            }
            console.log("Payload", payload)
            setLoading(true);
            let urlencoded = new URLSearchParams();
            urlencoded.append("contactNo", mobileNumber,"")
            // urlencoded.append("pincode", pincode)

            await fetch(process.env.REACT_APP_ALL_API + "/pmccares/insertpmccarescontactno", {
                method: 'POST',
                header: { 'Content-Type': 'application/x-www-form-urlencoded'},
                body: urlencoded,
            },
            )
                .then((response) => {
                    // console.log("first",response);
                    response.json();
                    console.log("asdfghjk",response)
                    if (response.status === 200) {
                        setLoading(false)
                        window.location.href = `https://wa.me/+919607014391?text= वरील क्रमांक 'DainikGomantak' नावाने सेव्ह केल्यानंतर आपली सेवा त्वरित सुरु करण्यात येईल.`
                    }
                    else {
                        setLoading(false)
                        alertPlaceholder.innerHTML = "";
                        alert('Server Error Please Try Again !', 'danger', alertPlaceholder);
                    }
                })
               .then(res => {
                     console.log("Response::::", res)
                })
                .catch(e => {console.log("ERROR", e);setLoading(false);})
        }
        else {
            return
        }
    }


    let opt = cities.length && cities.map((item) => <option value={item.name} key={item._id}>{item.name}</option>)

    return (
        <>
    
        <div >
        
            {/* // eslint-disable-next-line */}
            <Container fluid className='sendMsg'>
                <Row className='justify-content-center mt-2'>
                    <Col md={10}>
                        <img src={Banner} className="img-fluid" style={{ borderRadius: '4px' }} alt="Banner" />
                    </Col>
                </Row>

                <Container>
                    <Row className='justify-content-center flex-column'>
                        <Col md={4} className="contact-card mt-4 mx-auto">
                        <div className='py-3'>
                <form id="form1" onSubmit={(e) => { onSubmit(e); }} >

                    <div>
                        {/* <div className="input-group mb-3">
                  
                            <span className="" id="inputGroup-sizing-default" style={{ width: '100%', margin: '0px 20px' }}>Enter Pin Code</span>
                     
                            <input type="text" className="form-control" name="pincode" aria-label="Default" maxLength={6} style={{ width: '100%', height: '50px', margin: '0px 20px' }} />
                         
                        </div> */}
                    </div>
                    <div>
                        <div className="input-group mb-3">
                            {/* <div className="input-group-prepend"> */}
                            <span className="" id="inputGroup-sizing-default" style={{ width: '100%', margin: '0px 20px 5px 20px', fontWeight:'500' }}>Enter your whatsapp number<span className='text-danger'>*</span></span>
                            <input type="text" className="form-control" name="mobile" value={mobile} onChange={handleMobile}  aria-label="Default" maxLength={10} style={{ width: '100%', height: '50px', margin: '0px 20px', borderRadius:'6px' }} />
                            {mobileErr?<p style={{color:'red', margin: '0px 20px', fontSize:'12px'}}>{Error}</p>:null} 
                            {/* </div> */}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {loading? <button type="submit" className="btn btn-outline-success disabled"><Icon.Whatsapp className="me-1"/>WhatsApp Message</button>:<button type="submit" className="btn btn-outline-success"><Icon.Whatsapp className="me-2"/>WhatsApp Message</button>}
                    </div>
                </form>
                {loading?<center>
                    <div className="spinner-border text-primary mt-5" role="status" style={{zIndex:"10"}}>
                        {/* <span class="sr-only">Loading...</span> */}
                    </div>
                </center>:null}
            </div>
           
           </Col>
                        {/* <Col md={4} className='mx-auto'>
                        <div id="liveAlertPlaceholder" className='mt-2' style={{fontSize:'12px', padding:'0px'}}></div>
                        </Col> */}
                    </Row>
                 
                  
                </Container>

           
            </Container>
        </div>
        </>
    )
}
