import React from 'react'
// import sakalLogo from '../asset/sakalLogo.webp'
import NewLogo from '../asset/NewLogo.png';
import { Button } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import '../App.css';

export default function NavBar() {

    const  navigate = useNavigate();
    const handleLogout=()=>{
        localStorage.removeItem("User");
        navigate('/login');
    }
    const userData = localStorage.getItem("User");
    console.log("User");
    return (
        <nav className="navbar" style={{backgroundColor:"#1b186a", padding:"3px"}}>
            <div className="container d-flex">
                <a className="navbar-brand d-block mx-auto" href="/">
                    <img src={NewLogo} alt="Bootstrap" height="90" style={{borderRadius:"70px"}}/>
                </a>
                {userData ? <Button className="btn-submit" onClick={handleLogout}>Logout</Button> : null}
                
            </div>
        </nav>
        
    )
}
